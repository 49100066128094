
import { IResponse } from '@/common/http'
import { ElMessage } from 'element-plus'
import { computed, defineComponent, getCurrentInstance, onMounted, reactive, ref, toRefs } from 'vue'
import { useStore } from 'vuex'
import { easeInOutCubic } from '@/common/utils'
import { Base64 } from 'js-base64'
import { useRouter } from 'vue-router'
// import { useRouter } from 'vue-router'

interface ITableData {
  id: number,
  random: string,
  domain: string,
  link: string,
  password: string | undefined | null,
  name: string | undefined | null,
  pv: string,
  shortlink: string,
  createtime: string,
  expiretime: string,
  day: number | undefined
}

interface IState {
  initLoad: boolean,
  search: string,
  searchMode: boolean,
  page: number,
  pageSize: number,
  total: number,
  qrcode: string,
  delDialog: boolean,
  editDialog: boolean,
  editForms: ITableData | null,
  delForms: {
    id: number | null
  },
  qrcodeDialog: boolean,
  checkAll: boolean,
  isIndeterminate: boolean,
  checkedTable: Array<ITableData>
}

export default defineComponent({
  name: 'Link',
  setup () {
    /**
     * state 变量数据
     *
     * initLoad 初次加载态
     * search 搜索
     * page 页码
     * pageSize 每页数量
     * total 总数
     * qrcode 二维码
     * delDialog 删除对话框
     * editDialog 编辑对话框
     * editForms 编辑表单
     * |-name 链接备注
     * qrcodeDialog 二维码对话框
     * checkAll 全选
     * isIndeterminate 不确定状态
     */
    const state = reactive<IState>({
      initLoad: false,
      search: '',
      searchMode: false,
      page: 1,
      pageSize: 15,
      total: 0,
      qrcode: '',
      delDialog: false,
      editDialog: false,
      editForms: null,
      delForms: {
        id: null
      },
      qrcodeDialog: false,
      checkAll: false,
      isIndeterminate: false,
      checkedTable: []
    })
    const stateAsRefs = toRefs<IState>(state)
    const store = useStore()
    // const router = useRouter()
    const tableData = ref<ITableData[]>([])
    const { proxy }: any = getCurrentInstance()
    const responsively = computed<boolean>(() => store.getters.GET_RESPONSIVELY_MODE)

    const main = ref<any>(null)

    // 获取列表数据
    const getTableList = (): void => {
      if (state.searchMode) {
        state.searchMode = false
      }
      proxy.$axios.get('/link/mylink', {
        params: {
          page: state.page,
          page_size: state.pageSize
        }
      }).then((res: IResponse) => {
        if (res.code === 1) {
          res.data.lists.forEach((item: ITableData) => {
            item.shortlink = 'https://' + item.domain + '/' + item.random
          })
          state.total = res.data.count
          tableData.value = res.data.lists
          scrollToTop()
        } else {
          ElMessage.warning({
            message: res.msg,
            type: 'warning'
          })
        }
        state.initLoad = true
      })
    }
    getTableList()

    const handleSizeChange = (val: number): void => {
      state.pageSize = val
      if (state.search) {
        searchLink()
      } else {
        getTableList()
      }
    }
    const handleCurrentChange = (val: number): void => {
      state.page = val
      if (state.search) {
        searchLink()
      } else {
        getTableList()
      }
    }

    const copy = (val: string): void => {
      const input = document.createElement('input')
      input.value = val
      document.body.appendChild(input)
      input.select()
      document.execCommand('Copy')
      document.body.removeChild(input)
      ElMessage.success({
        message: '已复制到粘贴板',
        type: 'success'
      })
    }

    // 跳转链接
    const toLink = (url: string): void => {
      window.open(url, '_blank')
    }

    // 编辑链接
    const editLink = (): void => {
      const editForms: ITableData | null = state.editForms
      if (editForms) {
        proxy.$axios.post('/link/edit', {
          link_id: editForms.id,
          link: editForms.link,
          password: editForms.password,
          day: editForms.day,
          name: editForms.name
        })
          .then((res: IResponse) => {
            if (res.code === 1) {
              ElMessage.success({
                message: res.msg,
                type: 'success'
              })
            } else {
              ElMessage.warning({
                message: res.msg,
                type: 'warning'
              })
            }
            state.editDialog = false
            setTimeout(() => {
              getTableList()
            }, 100)
          })
      } else {
        ElMessage.error('表单数据不存在')
        state.editDialog = false
        setTimeout(() => {
          getTableList()
        }, 100)
      }
    }

    // 删除链接
    const delLink = (): void => {
      proxy.$axios.post('/link/del', {
        link_id: state.delForms.id
      }).then((res: IResponse) => {
        if (res.code === 1) {
          ElMessage.success({
            message: '删除成功',
            type: 'success'
          })
        } else {
          ElMessage.warning({
            message: res.msg,
            type: 'warning'
          })
        }
        state.delDialog = false
        setTimeout(() => {
          getTableList()
        }, 100)
      })
    }

    const router = useRouter()
    // Analysis 打开数据分析
    const openAnalysis = (item: ITableData): void => {
      const query = {
        shortlink: item.shortlink,
        random: item.random
      }
      const str = Base64.encode(JSON.stringify(query))
      const url = router.resolve({ path: '/data-analysis', query: { s: str } })
      window.open(url.href)
      // window.location.href = `https://data.suoba.cn/data-analysis?s=${str}`
      // window.location.href = `${store.getters.GET_ANALYSIS_URL}/data-analysis?s=${str}`
    }

    // QRCode Dialog 二维码对话框
    const openQrcode = (qrcode: string): void => {
      state.qrcode = qrcode
      state.qrcodeDialog = true
    }
    const closeQrcode = (): void => {
      state.qrcode = ''
    }

    // Edit Dialog 编辑对话框
    const openEdit = (item: ITableData): void => {
      state.editForms = Object.assign({}, item)
      state.editDialog = true
    }
    const closeEdit = (): void => {
      setTimeout(() => {
        state.editForms = null
      }, 500)
    }

    // Delete Dialog 删除对话框
    const openDelete = (id: number): void => {
      state.delForms.id = id
      state.delDialog = true
    }
    const closeDelete = (): void => {
      state.delForms.id = null
      state.delDialog = false
    }

    const handleCheckAllChange = (val: boolean): void => {
      state.checkedTable = val ? tableData.value : []
      state.isIndeterminate = false
    }

    const handleCheckedTableChange = (value: Array<string>): void => {
      const checkedCount = value.length
      state.checkAll = checkedCount === tableData.value.length
      state.isIndeterminate = checkedCount > 0 && checkedCount < tableData.value.length
    }

    const scrollToTop = (): void => {
      const beginTime = Date.now()
      const beginValue = main.value.scrollTop
      const rAF = window.requestAnimationFrame || (func => setTimeout(func, 16))
      const frameFunc = () => {
        const progress = (Date.now() - beginTime) / 500
        if (progress < 1) {
          main.value.scrollTop = beginValue * (1 - easeInOutCubic(progress))
          rAF(frameFunc)
        } else {
          main.value.scrollTop = 0
        }
      }
      rAF(frameFunc)
    }

    const searchLink = () => {
      if (!state.search) {
        getTableList()
      } else {
        if (!state.searchMode) {
          state.page = 1
        }
        proxy.$axios.get('/link/search', {
          params: {
            page: state.page,
            page_size: state.pageSize,
            random: state.search
          }
        }).then((res: IResponse) => {
          if (res.code === 1) {
            res.data.lists.forEach((item: ITableData) => {
              item.shortlink = 'https://' + item.domain + '/' + item.random
            })
            state.total = res.data.count
            tableData.value = res.data.lists
            state.searchMode = true
            scrollToTop()
          } else {
            ElMessage.warning({
              message: res.msg,
              type: 'warning'
            })
          }
        })
      }
    }

    onMounted(() => {
      main.value = document.querySelector('.el-main')
    })

    return {
      copy,
      main,
      toLink,
      tableData,
      editLink,
      getTableList,
      responsively,
      openQrcode,
      closeQrcode,
      openEdit,
      closeEdit,
      openAnalysis,
      openDelete,
      closeDelete,
      delLink,
      searchLink,
      handleSizeChange,
      handleCurrentChange,
      handleCheckAllChange,
      handleCheckedTableChange,
      ...stateAsRefs
    }
  }
})
