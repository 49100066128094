<template>
  <el-main>
    <!-- <el-card>
      <template #header>
        <div>
          <span>我的链接</span>
        </div>
      </template>
      <el-table
        :data="tableData"
        tooltip-effect="dark"
        empty-text="暂无链接"
        style="width: 100%; min-height: calc(100vh - 202px)"
      >
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column
          label="备注"
          width="140"
        >
          <template #default="scope">
            {{ scope.row.name ? scope.row.name : '(空)' }}
          </template>
        </el-table-column>
        <el-table-column
          label="缩链接"
          width="270"
        >
          <template #default="scope">
            <el-popover effect="light" trigger="hover" placement="top">
              <template #default>
                <p>地址: {{ 'https://' + scope.row.domain + '/' + scope.row.random }}</p>
              </template>
              <template #reference>
                <div class="name-wrapper">
                  <el-tag size="medium" @click="toLink('https://' + scope.row.domain + '/' + scope.row.random)">
                    {{ 'https://' + scope.row.domain + '/' + scope.row.random }}
                  </el-tag>
                </div>
              </template>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          prop="link"
          label="目标链接"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="pv"
          label="访问数量"
          width="120"
        >
        </el-table-column>
        <el-table-column
          prop="createtime"
          label="创建时间"
          min-width="180"
        >
        </el-table-column>
        <el-table-column
          label="操作"
          width="160">
          <template #default="scope">
            <el-button type="primary" size="small" @click="handleClick(scope.row)">编辑</el-button>
            <el-button type="danger" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card> -->
    <el-affix target=".el-main" :offset="60">
      <el-card class="page__header">
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
      <el-input size="mini" v-model="search" placeholder="请输入搜索内容">
        <template #append>
          <el-button icon="el-icon-search" @click="searchLink"></el-button>
        </template>
      </el-input>
      <el-pagination
        @size-change="handleSizeChange"
        :page-sizes="[1, 15, 100]"
        :page-size="pageSize"
        layout="sizes"
      />
    </el-card>
    </el-affix>
    <el-card class="page__data" v-if="tableData.length === 0 && initLoad">
      <el-empty description="暂无数据"></el-empty>
    </el-card>
    <el-checkbox-group v-model="checkedTable" @change="handleCheckedTableChange">
      <template v-for="(item, index) in tableData" :key="index">
        <el-card class="card-item">
          <template #header>
            <div class="header">
              <div class="left">
                <el-checkbox :label="item" :key="item.id">
                  <span class="name" @click="toLink(item.shortlink)">
                    {{ item.name || item.shortlink }}
                  </span>
                </el-checkbox>
                <div class="descript" @click="toLink(item.shortlink)">
                  {{ item.name ? item.shortlink : '' }}
                </div>
              </div>
              <div class="right">
                <div class="time">
                  {{ item.createtime }}
                </div>
              </div>
              <!-- <div class="name" @click="toLink(item.shortlink)">
                {{ item.name || item.shortlink }}
              </div>
              <div class="time">
                {{ item.createtime }}
              </div> -->
            </div>
            <!-- <div class="descript" @click="toLink(item.shortlink)">
              {{ item.name ? item.shortlink : '' }}
            </div> -->
          </template>
          <div class="item">
            <el-input disabled v-model="item.link">
              <template #append>
                <el-button @click.stop="copy(item.shortlink)" icon="el-icon-copy-document"></el-button>
              </template>
            </el-input>
          </div>
          <div class="item tag">
            <el-space wrap>
              <el-tag>访问数量：{{ item.pv }}</el-tag>
              <el-tag type="warning">访问UV：{{ item.uv }}</el-tag>
              <el-tag type="danger">访问IP：{{ item.ip }}</el-tag>
            </el-space>
          </div>
          <div class="item button-group">
            <el-tooltip class="tooltip" effect="dark" content="数据分析" placement="top">
              <el-button type="warning" @click="openAnalysis(item)" icon="el-icon-data-analysis" circle></el-button>
            </el-tooltip>
            <el-tooltip class="tooltip" effect="dark" content="二维码" placement="top">
              <el-button @click="openQrcode(item.qrcode)" type="success" circle><em class="iconfont icon-qr-code"></em></el-button>
            </el-tooltip>
            <el-tooltip class="tooltip" effect="dark" content="编辑" placement="top">
              <el-button @click="openEdit(item)" type="primary" icon="el-icon-edit" circle></el-button>
            </el-tooltip>
            <el-tooltip class="tooltip" effect="dark" content="删除" placement="top">
              <el-button @click="openDelete(item.id)" type="danger" icon="el-icon-delete" circle></el-button>
            </el-tooltip>
          </div>
        </el-card>
      </template>
    </el-checkbox-group>
    <el-card class="page__footer">
      <el-pagination
        background
        :small="responsively"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="pageSize"
        layout="total, prev, pager, next"
        :total="total"
      />
    </el-card>
    <!-- Dialog弹出层 -->
    <!-- 二维码对话框 -->
    <el-dialog
      append-to-body
      center
      top="30vh"
      :width="280"
      destroy-on-close
      title="链接二维码"
      @close="closeQrcode"
      v-model="qrcodeDialog"
    >
      <el-image :src="qrcode"></el-image>
    </el-dialog>
    <!-- 编辑对话框 -->
    <el-dialog
      append-to-body
      title="编辑链接"
      destroy-on-close
      :close-on-click-modal="false"
      :fullscreen="responsively"
      top="5vh"
      @close="closeEdit"
      v-model="editDialog"
    >
      <el-form ref="editForm" status-icon :model="editForms" label-position="top">
        <el-form-item label="短链接备注">
          <el-input placeholder="请输入短链接备注" clearable v-model="editForms.name" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="短链接">
          <p>{{ editForms.shortlink }}</p>
        </el-form-item>
        <el-form-item label="原链接">
          <el-input placeholder="请输入原链接" clearable v-model="editForms.link" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="失效日期">
          <p>{{ editForms.expiretime }}</p>
        </el-form-item>
        <el-form-item label="有效时间">
          <el-input placeholder="请输入有效时间(天数), 留空为永久" v-model="editForms.day">
            <template #append>天</template>
          </el-input>
        </el-form-item>
        <el-form-item label="访问密码">
          <el-input placeholder="保留为空即不更改" clearable v-model="editForms.password" auto-complete="off"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editDialog = false">取 消</el-button>
          <el-button type="primary" @click="editLink">保 存</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 删除对话框 -->
    <el-dialog
      append-to-body
      title="警告"
      destroy-on-close
      :width="responsively ? 280 : 480"
      top="30vh"
      @close="closeDelete"
      v-model="delDialog"
    >
      <p>确认删除此链接吗?</p>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="text" @click="delDialog = false">不用了</el-button>
          <el-button type="danger" @click="delLink">确 认</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- <el-backtop target=".el-main"></el-backtop> -->
  </el-main>
</template>

<script lang="ts">
import { IResponse } from '@/common/http'
import { ElMessage } from 'element-plus'
import { computed, defineComponent, getCurrentInstance, onMounted, reactive, ref, toRefs } from 'vue'
import { useStore } from 'vuex'
import { easeInOutCubic } from '@/common/utils'
import { Base64 } from 'js-base64'
import { useRouter } from 'vue-router'
// import { useRouter } from 'vue-router'

interface ITableData {
  id: number,
  random: string,
  domain: string,
  link: string,
  password: string | undefined | null,
  name: string | undefined | null,
  pv: string,
  shortlink: string,
  createtime: string,
  expiretime: string,
  day: number | undefined
}

interface IState {
  initLoad: boolean,
  search: string,
  searchMode: boolean,
  page: number,
  pageSize: number,
  total: number,
  qrcode: string,
  delDialog: boolean,
  editDialog: boolean,
  editForms: ITableData | null,
  delForms: {
    id: number | null
  },
  qrcodeDialog: boolean,
  checkAll: boolean,
  isIndeterminate: boolean,
  checkedTable: Array<ITableData>
}

export default defineComponent({
  name: 'Link',
  setup () {
    /**
     * state 变量数据
     *
     * initLoad 初次加载态
     * search 搜索
     * page 页码
     * pageSize 每页数量
     * total 总数
     * qrcode 二维码
     * delDialog 删除对话框
     * editDialog 编辑对话框
     * editForms 编辑表单
     * |-name 链接备注
     * qrcodeDialog 二维码对话框
     * checkAll 全选
     * isIndeterminate 不确定状态
     */
    const state = reactive<IState>({
      initLoad: false,
      search: '',
      searchMode: false,
      page: 1,
      pageSize: 15,
      total: 0,
      qrcode: '',
      delDialog: false,
      editDialog: false,
      editForms: null,
      delForms: {
        id: null
      },
      qrcodeDialog: false,
      checkAll: false,
      isIndeterminate: false,
      checkedTable: []
    })
    const stateAsRefs = toRefs<IState>(state)
    const store = useStore()
    // const router = useRouter()
    const tableData = ref<ITableData[]>([])
    const { proxy }: any = getCurrentInstance()
    const responsively = computed<boolean>(() => store.getters.GET_RESPONSIVELY_MODE)

    const main = ref<any>(null)

    // 获取列表数据
    const getTableList = (): void => {
      if (state.searchMode) {
        state.searchMode = false
      }
      proxy.$axios.get('/link/mylink', {
        params: {
          page: state.page,
          page_size: state.pageSize
        }
      }).then((res: IResponse) => {
        if (res.code === 1) {
          res.data.lists.forEach((item: ITableData) => {
            item.shortlink = 'https://' + item.domain + '/' + item.random
          })
          state.total = res.data.count
          tableData.value = res.data.lists
          scrollToTop()
        } else {
          ElMessage.warning({
            message: res.msg,
            type: 'warning'
          })
        }
        state.initLoad = true
      })
    }
    getTableList()

    const handleSizeChange = (val: number): void => {
      state.pageSize = val
      if (state.search) {
        searchLink()
      } else {
        getTableList()
      }
    }
    const handleCurrentChange = (val: number): void => {
      state.page = val
      if (state.search) {
        searchLink()
      } else {
        getTableList()
      }
    }

    const copy = (val: string): void => {
      const input = document.createElement('input')
      input.value = val
      document.body.appendChild(input)
      input.select()
      document.execCommand('Copy')
      document.body.removeChild(input)
      ElMessage.success({
        message: '已复制到粘贴板',
        type: 'success'
      })
    }

    // 跳转链接
    const toLink = (url: string): void => {
      window.open(url, '_blank')
    }

    // 编辑链接
    const editLink = (): void => {
      const editForms: ITableData | null = state.editForms
      if (editForms) {
        proxy.$axios.post('/link/edit', {
          link_id: editForms.id,
          link: editForms.link,
          password: editForms.password,
          day: editForms.day,
          name: editForms.name
        })
          .then((res: IResponse) => {
            if (res.code === 1) {
              ElMessage.success({
                message: res.msg,
                type: 'success'
              })
            } else {
              ElMessage.warning({
                message: res.msg,
                type: 'warning'
              })
            }
            state.editDialog = false
            setTimeout(() => {
              getTableList()
            }, 100)
          })
      } else {
        ElMessage.error('表单数据不存在')
        state.editDialog = false
        setTimeout(() => {
          getTableList()
        }, 100)
      }
    }

    // 删除链接
    const delLink = (): void => {
      proxy.$axios.post('/link/del', {
        link_id: state.delForms.id
      }).then((res: IResponse) => {
        if (res.code === 1) {
          ElMessage.success({
            message: '删除成功',
            type: 'success'
          })
        } else {
          ElMessage.warning({
            message: res.msg,
            type: 'warning'
          })
        }
        state.delDialog = false
        setTimeout(() => {
          getTableList()
        }, 100)
      })
    }

    const router = useRouter()
    // Analysis 打开数据分析
    const openAnalysis = (item: ITableData): void => {
      const query = {
        shortlink: item.shortlink,
        random: item.random
      }
      const str = Base64.encode(JSON.stringify(query))
      const url = router.resolve({ path: '/data-analysis', query: { s: str } })
      window.open(url.href)
      // window.location.href = `https://data.suoba.cn/data-analysis?s=${str}`
      // window.location.href = `${store.getters.GET_ANALYSIS_URL}/data-analysis?s=${str}`
    }

    // QRCode Dialog 二维码对话框
    const openQrcode = (qrcode: string): void => {
      state.qrcode = qrcode
      state.qrcodeDialog = true
    }
    const closeQrcode = (): void => {
      state.qrcode = ''
    }

    // Edit Dialog 编辑对话框
    const openEdit = (item: ITableData): void => {
      state.editForms = Object.assign({}, item)
      state.editDialog = true
    }
    const closeEdit = (): void => {
      setTimeout(() => {
        state.editForms = null
      }, 500)
    }

    // Delete Dialog 删除对话框
    const openDelete = (id: number): void => {
      state.delForms.id = id
      state.delDialog = true
    }
    const closeDelete = (): void => {
      state.delForms.id = null
      state.delDialog = false
    }

    const handleCheckAllChange = (val: boolean): void => {
      state.checkedTable = val ? tableData.value : []
      state.isIndeterminate = false
    }

    const handleCheckedTableChange = (value: Array<string>): void => {
      const checkedCount = value.length
      state.checkAll = checkedCount === tableData.value.length
      state.isIndeterminate = checkedCount > 0 && checkedCount < tableData.value.length
    }

    const scrollToTop = (): void => {
      const beginTime = Date.now()
      const beginValue = main.value.scrollTop
      const rAF = window.requestAnimationFrame || (func => setTimeout(func, 16))
      const frameFunc = () => {
        const progress = (Date.now() - beginTime) / 500
        if (progress < 1) {
          main.value.scrollTop = beginValue * (1 - easeInOutCubic(progress))
          rAF(frameFunc)
        } else {
          main.value.scrollTop = 0
        }
      }
      rAF(frameFunc)
    }

    const searchLink = () => {
      if (!state.search) {
        getTableList()
      } else {
        if (!state.searchMode) {
          state.page = 1
        }
        proxy.$axios.get('/link/search', {
          params: {
            page: state.page,
            page_size: state.pageSize,
            random: state.search
          }
        }).then((res: IResponse) => {
          if (res.code === 1) {
            res.data.lists.forEach((item: ITableData) => {
              item.shortlink = 'https://' + item.domain + '/' + item.random
            })
            state.total = res.data.count
            tableData.value = res.data.lists
            state.searchMode = true
            scrollToTop()
          } else {
            ElMessage.warning({
              message: res.msg,
              type: 'warning'
            })
          }
        })
      }
    }

    onMounted(() => {
      main.value = document.querySelector('.el-main')
    })

    return {
      copy,
      main,
      toLink,
      tableData,
      editLink,
      getTableList,
      responsively,
      openQrcode,
      closeQrcode,
      openEdit,
      closeEdit,
      openAnalysis,
      openDelete,
      closeDelete,
      delLink,
      searchLink,
      handleSizeChange,
      handleCurrentChange,
      handleCheckAllChange,
      handleCheckedTableChange,
      ...stateAsRefs
    }
  }
})
</script>

<style lang="scss" scoped>
  .el-tag {
    cursor: pointer;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      flex: 0 1 auto;
      overflow: hidden;
    }
    .right {
      padding-left: 12px;
      flex: 1 0 auto;
    }
    .name, .descript {
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .time {
      text-align: right;
      font-size: 14px;
      color: #C0C4CC;
    }
  }

  .descript {
    font-size: 14px;
    cursor: pointer;
    color: #C0C4CC;
  }

  .page {
    @mixin cardBody {
      :deep(.el-card__body) {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 8px;
      }
    }
    &__header {
      margin: 0 0 12px;
      :deep(.el-select .el-input) {
        width: 100px;
      }
      :deep(.el-pagination__sizes) {
        margin-right: 0;
      }
      @include cardBody;
      :deep(.el-input) {
        display: inline-flex;
      }
      :deep(.el-input-group__append) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &__data {
      height: calc(100% - 124px);
      :deep(.el-card) {
        height: 100%;
      }
      :deep(.el-card__body) {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        em {
          color: #E6A23C;
          font-size: 90px;
          margin-bottom: 24px;
        }
        h1 {
          font-size: 18px;
          color: #909399;
        }
      }
    }
    &__footer {
      margin: 12px 0 0;
      @include cardBody;
    }
  }

  .el-card {
    .item {
      & + .item {
        margin-top: 18px;
      }
    }
    .button-group {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .el-checkbox {
    padding: 0 6px;
  }

  .card-item {
    & + .card-item {
      margin-top: 18px;
    }
  }
</style>
